.searchResultMainContainer {
    margin: 100px auto;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: auto;
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: 200px auto;
    flex-direction: column;
}

.searchContainer {
    grid-row: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.grid2 {
    grid-template-columns: repeat(5, 1fr);
    grid-row: 2;
    height: auto;
    margin: 50px auto;
    justify-content: center;
    align-items: center;
    display: grid;
}

@media (max-width: 1400px) {
    .grid2 {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 1045px) {
    .grid2 {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 850px) {
    .grid2 {
        grid-template-columns: repeat(2, 1fr);
    }
}
