.submitButton {
  padding: 5px 15px;
  border-radius: 8px;
  background-color: #c20e21;
  color: white;
  outline: none;
  border: none;
  position:absolute;
}

.submitButton:hover {
  font-weight:bold;
}

.submitButton:focus {
  padding: 4px 13px;
}

