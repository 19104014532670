:root {
  --info-text-dark: rgba(0, 0, 0, .9);
  --info-text-light: rgba(255, 255, 255, .7);
}

#root {
  padding: 0;
  display: grid;
  top: 0;
  grid-template-rows: 10vh auto 25vmin;
  grid-template-columns: 100vw;
  position: relative;
}

.faq_container {
  width: 80vw;
  height: auto;
  margin: 20vh auto;
}

.mainContainer {
  margin: 0 auto;
  display: grid;
  grid-row: 2;
  width: 100%;
  padding: 2vh 0;
  grid-template-columns: 100%;
  grid-template-rows: 35vh auto auto auto;
}

.mainContainerGRID1 {
  grid-row: 1;
  grid-column: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.mainHomeIMG {
  grid-column: 1;
  height: 30vh;
  width: 100vw;
  position: absolute;
  object-fit: cover;
  object-position: center;
  z-index: 0;

}
.search_button {
  background-color: rgba(0, 0, 0, .8);
}

.categoryContainerImg {
  height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
  object-position: center;
  margin: 0;
}

.mainContainerGRID2 {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-rows: 10vh auto;
  justify-content: center;
}
.mainContainerGRID2GRID1 {
  grid-row: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainContainerGRID2GRID1 h2 {
  color: rgba(0, 0, 0, .8);

}
.mainContainerGRID2GRID2 {
  grid-row: 2;
  columns: 3;
  column-gap: 30px;
  padding: 4vh 2vw;
}

.productContainer {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23vw;
  height: 28vh;
  margin-bottom: 5vh;
  cursor: pointer;
  position: relative;
  overflow-x: hidden;
  box-shadow: var(--info-text-dark) 0 25px 50px -12px;
  border:  none;
  transition: .2s;
}
.productContainer:hover {
  border-radius: 10px;
}

.productContainer div {
  grid-column: 1;
  width: 100%;
  height: 20%;
}

.infoSectionMain {
  grid-row: 3;
  height: auto;
  position: relative;
  margin: 10vh auto;
  display: grid;
  grid-template-rows: 50vh 50vh 50vh;
  grid-template-columns: 35vw 20vw auto;
  overflow: hidden;
  background-color: #212529
}

.infoSectionMain div {
  width: 100%;
  height: 100%;
  display: flex;

}
.dark_light_container {
  position: fixed;
  width: 15%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #1a1a1a;
  left: 45%;
  background-color: #1a1a1a;
  border-radius: 0 0 15px 15px;
  z-index: 90;
}

.infoSectionMainImgSection {
  justify-content: center;
  align-items: center;
  padding: 3vh 2vw;
  overflow: visible;

}
.infoSectionImgContainer {
  overflow: hidden;
  border-radius: 15px;
}

.infoSectionIMG {
  width: 100%;
  object-position: center;
  object-fit: cover;
}
.infoSectionMainTextSection {
  align-items: center;
  padding: 3vh 2vw;
  overflow: visible;
}


.infoSectionMainTextSection div {
  align-items: center;
  flex-direction: column;
  padding: 2vh 3%;
  border-radius: 15px;
  background-color: #2D4356;
  overflow: visible;
}

.infoSectionTextDiv1 {
  grid-row: 1;
  grid-column: 2 / 4;
}

.infoSectionTextDiv2 {
  grid-row: 2;
  grid-column: 1 / 3;
}

.infoSectionTextDiv3 {
  grid-row: 3;
  grid-column: 2 / 4;
}


@media (max-width: 800px){
  .infoSectionTextDiv1 {
    grid-row: 1;
    grid-column: 1 / 4;
  }

  .infoSectionTextDiv2 {
    grid-row: 2;
    grid-column: 1 / 4;
  }

  .infoSectionTextDiv3 {
    grid-row: 3;
    grid-column: 1 / 4;
  }
}


.infoSectionMainTextSection div h4 {
  color: white;
  position: relative;
  margin-bottom: 5vh;
}

html {
  background-color: black;
}
.infoSectionMainText {
  font-size: .9rem;
  color: var(--info-text-light);
}

#imgROW1, #textROW1, #textROW3, #imgROW3 {
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s ease-in-out;
  opacity: 0;
}
#textROW2, #imgROW2 {
  filter: blur(5px);
  transform: translateX(100%);
  transition: all 1s ease-in-out;
  opacity: 0;
}


/* INFO TEXT BOTTOM */
.reminderContainer {
  width: 100%;
  border: 1px solid var(--info-text-dark);
  padding: 0;
  background-color: #212529;
  color: rgba(255, 255, 255, .8);
}
.reminderInnerContainer {
  width: 100%;
  padding: 4vh 2vw;
}

.dark_mode_button {
  margin: 0 auto;
  border: 1px solid black;
  border-radius: 0;
  cursor: pointer;

}

#search {
  width: 20vw;
}








@media (max-width: 1000px) {
  #faqField, #contactField {
    margin: 1vh 0;
  }
  #contactField {
    margin: 1vh 0 2vh 0;
  }
}




@media (max-width: 900px) {
  .mainContainerGRID2GRID2 {
    columns: 2;
    column-gap: 0;
    padding: 1vh 2vw;
  }
  .mainContainerGRID2 {
    grid-template-rows: 20vmin auto;
  }
  .productContainer {
    height: 36vh;
    width: 40vw;
    margin-top: 0;
  }
  .mainContainer {
    margin-top: 8vh;
    width: 90vw;
    padding: 1vh 0;
  }
  #search {
    height: 5vh;
    font-size: 80%;
    width: 30vw;
  }
}


@media (max-width: 650px) {
  .productContainer {
    height: 45vh;
    width: 90%;
    margin: 2vh auto;
  }
  .mainContainerGRID2GRID2 {
    columns: 1;
    column-gap: 0;
    padding: 1vh 2vw;
  }
  #search {
    height: 5vh;
    width: 76%;
    font-size: 80%;
  }
  #search_button {
    padding: 1vmin 2.5vmin;
    font-size: 10px;
  }
}


@media (max-width: 500px) {
  .mainContainerGRID2 {
    grid-template-rows: 20vmin auto;
  }
  .mainContainerGRID2GRID2 {
    columns: 1;
    column-gap: 0;
    padding: 1vh 2vw;
  }
  .productContainer {
    height: 40vh;
    width: 100%;
    margin-top: 0;
  }
  .mainContainer {
    margin-top: 8vh;
    width: 90vw;
    padding: 1vh 0;
  }
}


.swipe_box {
  background-color: rgba(0, 0, 0, .7);
  z-index: 4;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  filter: blur(5px);
  opacity: 0;
  transform: translateX(-100%);
  transition: all 1s ease-in-out;
}


.swipe_box p {
  color: rgba(255, 255, 255, .8);
  margin: auto;
}














footer {
  grid-row: 3;
  grid-column: 1;
  bottom: 5vmin;
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 15vh 10vh;
  margin: 30vmin 0;

}
footer div {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 2px;
}

.footerROW1 {
  grid-row: 1;
  grid-template-rows: 15vh;
  display: grid;
  grid-template-columns: 30% 35% 35%;
  background-color: rgb(47, 46, 47);
}
.footerROW1 div {
  height: 100%;
}
.footerROW1COL1 {
  grid-column: 1;
}

.logo_f {
  margin: auto;
  height: 10vmin;
}
.contactFooterDiv {
  display: flex;
  grid-column: 3;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;

}
.contactFooterDiv ul {
  right: 2vw;
  width: 50%;
  height: 100%;
  color: white;
  margin: auto 30px;
  padding: 0;
  list-style: none;
}
.contactFooterDiv ul li {
  flex-grow: 1;
  margin: 2px;
}
.contactFooterDiv ul li a {
  color: rgba(255, 255, 255, .6);
  font-size: 12px;

}
.contactFooterDiv ul li a:hover {
  color: rgba(255, 255, 255, .9);
  text-shadow: 0 0 1px 1px rgba(255, 255, 255, .9)

}
.footerROW2 {
  grid-row: 2;
  display: grid;
  width: 100%;
  text-align: center;
  height: 100%;
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: 100%;
  margin-top: 3vh;
}
.footerROW2COL2 {
  grid-row: 1;
  grid-column: 2;
  padding: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba( 0, 0, 0, .1)

}
.footerROW2COL2 p {
  font-size: 14px;
}
.gradient {
  background-image: linear-gradient(135deg, #684ca0 35%, #1c4ca0 100%);
}
.footerROW1COL2 {
  grid-column: 2;
}

.footerROW1COL2 ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}
.footerROW1COL2 ul li p {
  color: rgba(255, 255, 255, .8);
  font-size: 14px;
}
.footerGRID2 {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notFoundMain {
  width: 80vw;
  height: auto;
  display: flex;
  padding: 2vh 4vw;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 10vh auto;
  color: white;
}
.goHomeButton {
  padding:  1rem 3rem;
  border-radius: 14px;
  font-size: 18px;
  font-weight: bold;
  position: relative;
}
.headerContainer {
  width: 100%;
  grid-row: 1;
  margin: 0;
  position: relative;
}

.mainContent {
  grid-row: 2;
  grid-column: 1;
  width: 100%;
  height: 100%;
  margin: 0;

}


.loading_section {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoContainer {
  display: grid;
  grid-template-rows: 20% 60% 20%;
  grid-template-columns: 20% 60% 20%;
  width: 80vw;
  height: 60vh;
  margin: 20vmin auto;
  border: 1px solid rgba(0, 0, 0, .1);
  border-radius: 10px;

}

.infoContainer div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.infoContainerGRID1 {
  grid-row: 1;
  grid-column: 2;
  border-bottom: 1px solid rgba(0, 0, 0, .1);


}

.infoContainerGRID1 h2 {
  color: rgba(0, 0, 0, .6);
}

.infoContainerGRID2 {
  grid-row: 2;
  grid-column: 2;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, .1);


}
.infoContainerGRID2 p {
  color: rgba(0, 0, 0, .6);
  font-size: 16px;

}



@media (orientation: landscape) {
  .infoContainer {

  }
}
@media (orientation: portrait) {
  .infoContainer {
    width: 80vw;
    height: 60vh
  }
}

.alternative_mail_container {
  margin: 0 5vmin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 3vmin;
  width: 70%;
}

@media (max-width: 525px) {
  .alternative_mail_container p {
    font-size: .6rem;
  }
}


.logo_h {
  height: 6vmin;
  margin: auto 3vmin;
}




/*****************+MODAL*/
.modal-confirm {
  color: #434e65;
  width: 525px;
  margin: 30px auto;
}
.modal-confirm .modal-content {
  padding: 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
}
.modal-confirm .modal-header {
  background: #47c9a2;
  border-bottom: none;
  position: relative;
  text-align: center;
  margin: -20px -20px 0;
  border-radius: 5px 5px 0 0;
  padding: 35px;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 36px;
  margin: 10px 0;
}
.modal-confirm .form-control, .modal-confirm .btn {
  min-height: 40px;
  border-radius: 3px;
}
.modal-confirm .close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  text-shadow: none;
  opacity: 0.5;
}
.modal-confirm .close:hover {
  opacity: 0.8;
}
.modal-confirm .icon-box {
  color: #fff;
  width: 95px;
  height: 95px;
  display: inline-block;
  border-radius: 50%;
  z-index: 9;
  padding: 15px;
  text-align: center;
}
.modal-confirm .icon-box i {
  font-size: 64px;
  margin: -4px 0 0 -4px;
}
.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: #eeb711;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  margin-top: 10px;
  padding: 6px 20px;
  border: none;
}
.modal-confirm .btn:hover, .modal-confirm .btn:focus {
  background: #eda645;
  outline: none;
}
.modal-confirm .btn span {
  margin: 1px 3px 0;
  float: left;
}
.modal-confirm .btn i {
  margin-left: 1px;
  font-size: 20px;
  float: right;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}


.modal_loadingDiv {
  width: 70vw;
  height: auto;
  margin: 10vh auto;
}


.superHeader {
  font-weight: bold;
  color: rgba(255, 255, 255, .95);
  text-decoration: unset;
}



.amzSmile {
  position: relative;
  top:0;
  width: 10vmin;
}




.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}


/* searchResult */
.searchResultMain {
  margin: 5vh auto;
  padding: 3vh 3vw;
  display: grid;
  grid-template-rows: 20vmin auto;
  grid-template-columns: 80vw;
  justify-content: center;
  align-items: flex-start;
}



.searchResultHeader {
  grid-row: 1;
  flex-direction: column;
}
.searchResultHeader h3 {
  color: rgba(0, 0, 0, .8);
}
.searchResultHeader p {
  margin-top: 3vh;
}

.searchResultContent {
  grid-row: 2;
  display: flex;
  columns: 1;
  flex-direction: column;
  height: auto;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  width: 100%;
}


.productImageContainer {
  padding: 1%;
  grid-column: 2;
  grid-row: 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.productImage {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  z-index: 5;
}

.productIndex {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1;
  grid-row: 1;
  z-index: 4;
  background-color: rgba(0, 0, 0, .2);
}
.productElement {
  display: grid;
  grid-template-columns: 4vw 19vw 52vw;
  height: 35vh;
  margin-bottom: 2vh;
  transition: 1s ease-in-out;
  position: relative;
}

.informationContainer {
  grid-column: 3;
  grid-row: 1;
  display: grid;
  grid-template-columns: 70% 30%;
  background-color: rgb(52, 58, 64);
}

.ul-container {
  grid-column: 1;
  display: flex;
  flex-direction: column;

}

.productHeading {
  padding: 2% 10% 5px 1%;
  overflow: hidden;
  height: 20vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
}

.headerText {
  font-size: .2rem;
  color: rgba(255, 255, 255, .95);
}

.ul {
  height: 23vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ul ul  {
  list-style: initial;

}
.ul ul li {
  color: rgba(255, 255, 255, .85);
}

.recensionsContainer > * {
  z-index: 8;
}
.recensionsContainer svg {
  height: 10px;
  width: 10px;
}

.amazonLogo {
  width: 60%;
  height: 15%;
}
.stars {
  width: 50%;
  height: 20%;
}

.informationContainer button {
  border-radius: 21px;
  background-color:  rgb(255,153,0);
  font-size: 90%;
  padding: .2rem .6rem;
  color: white;
}


@media (max-width: 2000px) {
  .productElement {
    height: 34vh;
    width: 80vw;
    grid-template-rows: 50% 50%;
    grid-template-columns: 50% 50%;
    color: rgba(255, 255, 255, .7);
  }

  .productImageContainer {
    grid-column: 1;
    grid-row: 1 / 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .carouselContainer {
    width: 85%;
    height: 100%;
    overflow: hidden
  }
  .productImage {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;

  }

  .productIndex {
    width: 15%;
    height: 100%;
    display: flex;
    padding: 1%;
    justify-content: center;
    align-items: center;
    grid-column: 1 ;
    grid-row: 1 / 3;
    z-index: 4;
    background-color: rgba(0, 0, 0, .2);
  }

  .informationContainer {
    grid-column: 2;
    grid-row: 1 / 3;
    display: grid;
    grid-template-columns: 65% 35%;
    background-color: rgb(52, 58, 64);
    padding: 2% 3% 0 2%;
  }

  .informationContainer button {
    border-radius: 21px;
    background-color: rgb(255, 153, 0);
    font-size: 60%;
    padding: .2rem .6rem;
    color: white;
    font-weight: bold;
  }

  .productHeading {
    padding: 1% 0 6% 1%;
    overflow: hidden;
    width: 100%;
    grid-column: 1;
    display: block;
    height: 10vh;
    justify-content: flex-start;
    align-items: flex-start;
    white-space: pre-line;
  }

  .show_more_a {
    font-size: .4rem;
    right: -70%;
    top: 14px;
    position: relative;
  }


  .headerText {
    font-size: 1rem;
  }

  .recensionsContainer {
    grid-row: 1 / 3;
    z-index: 5;
    grid-column: 2;
    padding: 15px 1%;
    margin: auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }

  .recensionsContainer button {
    border-radius: 21px;
    background-color: rgb(255, 153, 0);
    padding: 7px 15px;
    color: white;
    margin-top: 2%;
    font-size: 12px;
  }
  .recensionsContainer button:hover {
    opacity: .7;
    transition: all .25s ease-in-out;
  }
  .stars {
    width: 30%;
    height: 28%
  }
  .ul-container:hover::after {
    background: linear-gradient(to bottom, rgba(255, 153, 0, 1), rgba(0, 0, 0, 0.9));
  }

  .amazonLogo {
    width: 40%;
    height: 27%;
  }

  .ul {
    margin-top: 10px;
    grid-column: 1;
    width: 100%;
    display: flex;
    padding: 5px 0;
    border-top: 1px solid rgba(255, 255, 255, .14);
    overflow: hidden;


  }
  .ul ul {
    position: relative;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
  }

  .ul ul li {
    color: rgba(255, 255, 255, .9);
    font-size: .8rem;
    flex-grow: 1;
  }
}
/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
@media (max-width: 1200px) {
  .ul {
    margin-top: 0;
    top: 5px;
    display: flex;
    padding: 5px 1% 3px 0;
    overflow: hidden;
  }
  .ul ul {
    text-align: start;
    display: flex;
    list-style: revert;
    flex-direction: column;
    left: 0;
  }
  .ul ul li {
    text-align: start;
    color: rgba(255, 255, 255, .9);
    font-size: .6rem;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 0;
    left: 0;
    position: relative;
  }
  .amazonLogo {
    width: 50%;
    height: 25%;
  }
  .recensionsContainer button{
    font-size: 10px;
    padding: 3px 5px;
    font-weight: normal;
    margin-top: 0;
  }
  .informationContainer {
    grid-column: 2;
    grid-row: 1 / 3;
    display: flex;
    background-color: rgb(52, 58, 64);
    flex-direction: column;
    padding: 2% 3% 0 2%;

  }
  .productHeading {
    grid-row: 1;
    grid-column:1 / 2;
    padding: 2% 5% 1% 0;
    display: block;
    align-items: flex-start;
    height: 16vh;
    text-align: start;
    justify-content: flex-start;
    overflow: hidden;
    flex-grow: 1;
  }
  .headerText {
    line-height: 16px;
    font-size: 15px;
  }
  .carouselContainer {
    width: 100%;
    height: 100%;
    overflow: hidden
  }

  .stars {
    width: 40%;
    height: 25%
  }
}



@media (max-width: 1030px) {
  .productImageContainer {
    grid-column: 1;
    grid-row: 1 / 3;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .carouselContainer {
    width: 100%;
    height: 75%;
    overflow: hidden
  }
  .productImage {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
  }
  .productIndex {
    width: 30%;
    height: 50%;
    display: flex;
    padding: 1%;
    justify-content: center;
    align-items: flex-start;
    grid-column: 1;
    grid-row: 1;
    z-index: 4;
    background-color: rgba(0, 0, 0, .2);
  }
}
@media (max-width: 950px) {
  .productImageContainer {
    grid-column: 1;
    grid-row: 1 / 3;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 3vh;
  }
  .productImage {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
  }
  .recensionsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ul {
    margin-top: 0;
    top: 5px;
    position: relative;
    display: flex;
    padding: 5px 1% 3px 0;
    overflow: hidden;
  }
  .ul ul {
    text-align: start;
    display: flex;
    list-style: revert;
    flex-direction: column;
    left: 0;
  }
  .ul ul li {
    text-align: start;
    color: rgba(255, 255, 255, .9);
    font-size: .6rem;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 0;
    left: 0;
    position: relative;
  }
  .amazonLogo {
    width: 50%;
    height: 25%;
  }
  .recensionsContainer button{
    font-size: 10px;
    padding: 3px 5px;
    font-weight: normal;
    margin-top: 0;
  }
  .informationContainer {
    grid-column: 2;
    grid-row: 1 / 3;
    display: flex;
    background-color: rgb(52, 58, 64);

    flex-direction: column;
    padding: 2% 3% 0 2%;

  }
  .productHeading {
    grid-row: 1;
    grid-column:1 / 2;
    padding: 2% 5% 1% 0;
    display: block;
    align-items: flex-start;
    height: 16vh;
    text-align: start;
    justify-content: flex-start;
    overflow: hidden;
    flex-grow: 1;
  }

  .carouselContainer {
    width: 100%;
    height: 80%;
    overflow: hidden;
    justify-content: center;
    align-items:center;
  }

  .stars {
    width: 40%;
    height: 25%
  }
}

@media (max-width: 550px) {

  .productIndex {
    width: 40%;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 1;
    grid-row: 1;
    z-index: 4;
    background-color: rgba(0, 0, 0, .2);
  }
  .headerText {
    font-size: 14px;
  }
  .productImageContainer {
    grid-column: 1;
    grid-row: 1 / 3;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .recensionsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ul {
    margin-top: 0;
    top: 5px;
    position: relative;
    display: flex;
    padding: 5px 1% 3px 0;
    overflow: hidden;
  }
  .ul ul {
    position: relative;
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    list-style: revert;
    flex-direction: column;
    left: -1px;
  }
  .ul ul li {
    text-align: start;
    color: rgba(255, 255, 255, .9);
    font-size: .6rem;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding: 0;
    left: -20px;
    position: relative;
  }
  .amazonLogo {
    width: 50%;
    height: 25%;
  }
  .recensionsContainer button{
    font-size: 10px;
    padding: 3px 5px;
    font-weight: normal;
    margin-top: 0;
  }
  .informationContainer {
    grid-column: 2;
    grid-row: 1 / 3;
    display: flex;
    background-color: rgb(52, 58, 64);
    flex-direction: column;
    padding: 2% 3% 0 2%;
  }
  .productHeading {
    grid-row: 1;
    grid-column:1 / 2;
    padding: 2% 5% 1% 0;
    display: block;
    align-items: flex-start;
    height: 16vh;
    text-align: start;
    justify-content: flex-start;
    overflow: hidden;
    flex-grow: 1;
  }
  .carouselContainer {
    width: 100%;
    height: 70%;
    overflow: hidden
  }

  .stars {
    width: 40%;
    height: 25%
  }
}

.prev_col1 {
  display: flex;
  flex-direction: column;
  grid-column: 1;
  grid-row: 1;
  justify-content: flex-end;
  align-items: center;
}

.prev_col2 {
  grid-column: 2;
  grid-row: 1;
  padding: 50px 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.codeInput {
  background: #3f3f3f;
  outline: none;
  color: white;
  padding: 10px;
  border-radius: 14px;

}
.outputOutlineContainer {
  padding: 10px 20px 30px 20px;
  border-radius: 14px;
  display: flex;
  width: 740px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.iframeCodeOutput {
  width: 700px;
  height: 700px;
  padding: 5px 10px;
  background: #3f3f3f;
  outline: 0;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.codeInputDIV {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content:flex-start;
  margin-bottom: 10px;
  padding: 10px 15px 30px 15px;
  border-radius: 14px;
}
.main_container_p_real {
  border-radius: 14px;
  width: 90%;
  margin: 50px auto;
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 50% 50%;
  justify-content: flex-end;
  align-items: center;
  background: #d3d3d3;
  padding: 50px 20px
}
.labelPreview {
  color: white;
}
.inputContainer {
  grid-column: 1;
  grid-row: 1;
  margin: 0;
  position: relative;
  width: 900px;
}

.inputPreviewContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 18px;
  border: 1px solid black;
  padding: 10px 80px 10px 10px;
  width: 800px;
  flex-direction: row;
}

.previewInput {
  border-radius: 14px;
  outline: none;
  border: none;
}

.synthaxHighlighter {
  position: absolute;
}

.imageInputzField {
  height: 40px;
  width: 60px;
  background: #1c4ca0;
  z-index: 90;
  cursor: pointer;
  position: absolute;
}