.inputContainerMain {

}

.inputContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  border-radius: 9px;
  background-color: white;
  height: 50px;
  width: 300px;
}

.searchInput {
  border: none;
  outline: none;
  background-color: transparent;
  text-align: start;
  flex-grow: 1;
  border-radius: 9px;
  padding: 5px 15px;
  color: black;
}

.searchInput:hover {
  outline: none;
}
.searchInput:focus {
  outline: none;
}
.searchButton {
  background-color: white;
  justify-content: center;
  align-items: center;
  color: black;
  flex-direction: row;
  display: flex;
  padding: 7px;
  width: 50px;
  height: 50px;
  border-radius: 9px;
}

