.searchForm {
  margin: 0 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  border: 1px solid red;
}

.formInput {
  border-radius: 6px;
  padding: 6px 5px;
  border: none;
}
.formInput:hover {
  outline: none;
}
.formInput:focus {
  outline: none;
}

.headerButtonSearch {
  border-radius: 11px;
  background-color: black;
  opacity: .6;
  color: #646cff;
  padding: 7px 19px;
  margin-left: 20px;
}
.headerButtonSearch:hover{
  box-shadow: 0 0 20px #646cff;
  color: white;
  opacity: .8;
}

.darkBg {
  background-color: #212529;
}

.footerMainContainer {
  width: 100%;
  height: 300px;
}

.footerAnchor {
  color: white;
  margin-bottom: 10px;
  text-decoration: none;
}


.footerMain {
  width: 100%;
}

/* HEADER */
.headerMain {
  width: 100vh
}

/* LOGO IMG */
.logo {
  cursor: pointer;
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

/* LOGO CONTAINER */
.amz_partnerDiv {
  display: flex;
  align-items: center;
  flex-direction: row;
}
