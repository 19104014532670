

.productCard{
    cursor: pointer;
    height: 400px;
    margin: 10px;
    background-color: rgb(33, 37, 41);
    border:none;
    display: flex;
    position: relative;
    border-radius: 10px;
    width: 240px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.productCard:hover {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.image-container{
    width: 90%;
}

.thumbnail-image{
    border-radius: 10px;
    width: 220px;
    height: 240px;
    margin-top: 10px;
    object-fit: contain;
}

.discount{
    background-color: red;
    padding: 5px 2px;
    font-size: 10px;
    border-radius: 50%;
    color: #fff;
}

.titleContainer {
    width: 90%;
    overflow: hidden;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 5px 0;
    display: flex;
    flex-direction: column;
}
.valueContainer {
    width: 100%;
}

.shippingPriceText {
    font-size: 10px;
    opacity: .8;
}
.borderClass {
    border: 1px solid red;
}

.price {
    font-size: 15px;
    color: white;
    font-weight: bold;
}




.wishlist{
    height: 25px;
    width: 25px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.first{
    position: absolute;
    width: 90%;
    padding: 9px;
}


.dress-name{
    font-size: 13px;
    font-weight: bold;
    width: 100%;
}


.new-price{
    font-size: 11px;
    font-weight: bold;
    color: green;
}

.old-price{
    font-size: 8px;
    font-weight: bold;
    color: grey;
}


.btn{
    width: 14px;
    height: 14px;
    border-radius: 50%;
    padding: 3px;
}


.item-size{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid grey;
    color: grey;
    font-size: 10px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
}


.rating-star{
    font-size: 10px !important;
}
.rating-number{
    font-size: 10px;
    color: grey;

}
.buy{
    font-size: 12px;
    color: purple;
    font-weight: 500;
}














.voutchers{
    background-color: #fff;
    border:none;
    border-radius: 10px;
    width: 190px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: hidden;

}
.voutcher-divider{

    display: flex;

}
.voutcher-left{
    width: 60%
}
.voutcher-name{
    color: grey;
    font-size: 9px;
    font-weight: 500;
}
.voutcher-code{
    color: red;
    font-size: 11px;
    font-weight: bold;
}
.voutcher-right{
    width: 40%;
    background-color: purple;
    color: #fff;
}

.discount-percent{
    font-size: 12px;
    font-weight: bold;
    position: relative;
    top: 5px;
}
.off{
    font-size: 14px;
    position: relative;
    bottom: 5px;
}